import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CustomLogoutComponent from "./Logout/Logout";
import CustomLoginComponent from "./Login/Login";
import CustomCallbackComponent from "./Login/Callback";
import GraphiQLView from "./GraphiQLView/GraphiQLView";
import GraphQLVoyager from "./GraphQLVoyager/GraphQLVoyager";

const App = () => (
  <Router>
    <Route path="/login" component={CustomLoginComponent} />
    <Route path="/logout" component={CustomLogoutComponent} />
    <Route path="/implicit/callback" component={CustomCallbackComponent} />
    <Route path="/" exact component={GraphiQLView} />
    <Route path="/diagram" exact component={GraphQLVoyager} />
  </Router>
);

export default App;
