// @flow
import React, { useState, useEffect } from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import { useHistory } from "react-router-dom";
import { buildClientSchema, getIntrospectionQuery, parse } from "graphql";

import { makeDefaultArg, getDefaultScalarArgValue } from "../CustomArgs";

import "graphiql/graphiql.css";
import "./GraphiQLView.css";
import { Auth } from "aws-amplify";



const DEFAULT_QUERY = ``;

const DEFAULT_QUERY_VARIABLES = ``;

export default function() {
  const [state, settingState] = useState({
    schema: null,
    query: DEFAULT_QUERY,
    explorerIsOpen: true,
    notSet: true,
    logout_label: "Logout",
    access_token: null
  });

  let _graphiql = {};
  const history = useHistory();
  const logout = () => {
    setInterval(() => {
      localStorage.clear();
      history.push("/login");
    }, 500);
  }

  const navigateToDiagram = () => {
    setInterval(() => {
      window.location.href = "/diagram"
    }, 100);
  }

  useEffect(() => {
    function setUserEmail() {

      Auth.currentAuthenticatedUser()
      .then(user => {
        if (user) {
          var id_token = user.getSignInUserSession().getIdToken();
          var access_token = user.getSignInUserSession().getAccessToken().jwtToken;

          if(id_token && id_token.payload && id_token.payload.email) {
            state.logout_label =  "Logout (" + id_token.payload.email +")";
          }

          if(access_token) {
            state.access_token = access_token;
          }

        } else {
          logout();
        }

        return user;
      })
      .catch(ex => {
        logout();
      });
    }

    setUserEmail();

    if (_graphiql && state.notSet) {
      const editor = _graphiql.getQueryEditor();
      fetcher({ query: getIntrospectionQuery() }).then((result) => {
        editor.setOption("extraKeys", {
          ...(editor.options.extraKeys || {}),
          "Shift-Alt-LeftClick": _handleInspectOperation,
        });

        if(result) {
          settingState({ ...state, schema: buildClientSchema(result.data) });
        }
      });
    }
  }, []);

  const fetcher = (params) => {
    var token = localStorage.getItem("token")
    return fetch(process.env.REACT_APP_GRAPHQL_API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": token ? token : null,
      },
      body: JSON.stringify(params),
    })
      .then(async function(response) {
        if(response.status === 401) {
          await Auth.signOut();
          return null;
        }

        return response.text();
      })
      .then(function(responseBody) {
        try {
          return JSON.parse(responseBody);
        } catch (e) {
          return responseBody;
        }
      });
  }

  const _handleInspectOperation = (cm, mousePos) => {
    const parsedQuery = parse(state.query || "");

    if (!parsedQuery) {
      console.error("Couldn't parse query document");
      return null;
    }

    var token = cm.getTokenAt(mousePos);
    var start = { line: mousePos.line, ch: token.start };
    var end = { line: mousePos.line, ch: token.end };
    var relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end),
    };

    var position = relevantMousePos;

    var def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        console.log("Missing location information for definition");
        return false;
      }

      const { start, end } = definition.loc;
      return start <= position.start && end >= position.end;
    });

    if (!def) {
      console.error(
        "Unable to find definition corresponding to mouse position"
      );
      return null;
    }

    var operationKind =
      def.kind === "OperationDefinition"
        ? def.operation
        : def.kind === "FragmentDefinition"
        ? "fragment"
        : "unknown";

    var operationName =
      def.kind === "OperationDefinition" && !!def.name
        ? def.name.value
        : def.kind === "FragmentDefinition" && !!def.name
        ? def.name.value
        : "unknown";

    var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

    var el = document.querySelector(selector);
    el && el.scrollIntoView();
  };

  const _handleEditQuery = (query) => settingState({ ...state, query });

  const _handleToggleExplorer = () => {
    settingState({ ...state, explorerIsOpen: !state.explorerIsOpen });
  };

  return (
    <div className="graphiql-container">
      <GraphiQLExplorer
        schema={state.schema}
        query={state.query}
        onEdit={_handleEditQuery}
        onRunOperation={(operationName) =>
          _graphiql.handleRunQuery(operationName)
        }
        explorerIsOpen={state.explorerIsOpen}
        onToggleExplorer={_handleToggleExplorer}
        getDefaultScalarArgValue={getDefaultScalarArgValue}
        makeDefaultArg={makeDefaultArg}
      />
      <GraphiQL
        ref={(ref) => (_graphiql = ref)}
        fetcher={fetcher}
        schema={state.schema}
        query={state.query}
        onEditQuery={_handleEditQuery}
        variables={DEFAULT_QUERY_VARIABLES}
      >
        <GraphiQL.Logo>
          <img src="/logos/icon-accenture.svg" alt="Accenture Logo" />
        </GraphiQL.Logo>
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            onClick={() => _graphiql.handlePrettifyQuery()}
            label="Prettify"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={() => _graphiql.handleToggleHistory()}
            label="History"
            title="Show History"
          />
          <GraphiQL.Button
            onClick={_handleToggleExplorer}
            label="Explorer"
            title="Toggle Explorer"
          />
          <GraphiQL.Button
            onClick={navigateToDiagram}
            label="Diagram"
            title="Diagram"
          />
          <GraphiQL.Button onClick={logout} label={state.logout_label} title="Logout" />
        </GraphiQL.Toolbar>
      </GraphiQL>
    </div>
  );
}
