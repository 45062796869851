import React from "react";
import ReactDOM from "react-dom";
import "./polyfills";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Amplify from '@aws-amplify/core';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: "REFRESH_TOKEN_AUTH",
    oauth: {
      domain: process.env.REACT_APP_COGNITO_APP_DOMAIN,
      scope: [],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URI_SIGNOUT,
      responseType: "code"
    }
  }
});

Sentry.init({
  debug: false,
  dsn: process.env.REACT_APP_SENTRY_KEY,
  environment: process.env.REACT_APP_NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
